<template>
  <div class="app-container">
    <CrudTable
      entity="CouponCode"
      :columns="columns"
      :permission-code="'CouponCode'"
      :default-filters="{ onlineYN: 1 }"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { toDateStringVisual } from '@/utils/date';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        'code',
        { field: 'createdByRestaurantName', label: 'coupon.createdByRestaurantName' },
        {
          field: 'couponStartDate',
          label: 'coupon.startDateTime',
          formatter: this.formatDate,
          filter: { type: 'date' }
        },
        {
          field: 'couponEndDate',
          label: 'coupon.endDateTime',
          formatter: this.formatDate,
          filter: { type: 'date' }
        },
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  methods: {
    formatDate(row, column, cellValue) {
      return toDateStringVisual(cellValue);
    }
  }
};
</script>

<style scoped></style>
